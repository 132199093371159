import { useEffect, useState } from "react";
import { ApiCall } from "../../config";
import ReplaceProductComponent from "./ReplaceProductComponent";
import { AddBrands, Button } from "../../components";
import ProductDetails from "./ProductDetails";

function ReplaceProducController({ isClick }: any) {
  const [data, setdata] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>(1);
  const [size, setSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [replacedProduct, setReplacedProduct] = useState<any>("");
  const [soldProduct, setSoldProduct] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  const ReplaceProductListApi = async () => {
    setIsLoading(true);
    const response = await ApiCall({
      endpoint: "replace/list",
      method: "POST",
      data: {
        limit: size,
        page: selectedPage?.selected ?? 1,
        columnName: sortData,
        order: orderBy ?? "ASC",
        search: searchInput,
      },
    });
    setIsLoading(false);
    setdata(response?.data);
  };

  useEffect(() => {
    ReplaceProductListApi();
  }, [size, selectedPage, searchInput, orderBy, sortData]);

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const replaceNewProductDetails = (value: any) => {
    setReplacedProduct(value?.product_replaced_by);
    setSoldProduct(value?.product_sold_by);
  };
  const replaceOldProductDetails = (value: any) => {
    setReplacedProduct(value?.product_replaced_by);
    setSoldProduct(value?.product_sold_by);
  };
  const onShortData = (value: any) => {
    if (value !== sortData) {
      setSortData(value);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };

  const closeUserDetailsPopup = () => {
    setReplacedProduct("");
    setSoldProduct("");
  };

  return (
    <div>
      <ReplaceProductComponent
        isClick={isClick}
        data={data}
        handleChangeSearch={handleChangeSearch}
        searchInput={searchInput}
        selectedPage={selectedPage}
        setSize={setSize}
        setSelectedPage={setSelectedPage}
        onShortData={onShortData}
        size={size}
        replaceNewProductDetails={replaceNewProductDetails}
        replaceOldProductDetails={replaceOldProductDetails}
        isLoading={isLoading}
      />
      {replacedProduct && soldProduct ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">USERS DETAILS</span>
              </div>
              <hr />
              <div className="addretailer-second">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <ProductDetails
                    title="Product Replaced By "
                    product={replacedProduct}
                  />
                  <ProductDetails
                    title="Product Sold By"
                    product={soldProduct}
                  />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={closeUserDetailsPopup}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default ReplaceProducController;
