import React from "react";
import "./ReplaceProduct.scss";

function ProductDetails({ title, product }: any) {
  return (
    <div>
      <div className="col-md-5">
        <h2 className="product-details-title">{title}</h2>
        <div className="product-details">
          <div className="product-item-div">
            <span className="product-title">GST : </span>
            <span className="product-desc">{product?.gst}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">First Name : </span>
            <span className="product-desc">{product?.first_name}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">Last Name : </span>
            <span className="product-desc">{product?.last_name}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">Phone Number : </span>
            <span className="product-desc">{product?.phone_number}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">Pan Card : </span>
            <span className="product-desc">{product?.pan_card}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">Business Name : </span>
            <span className="product-desc">{product?.business_name}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">User Type : </span>
            <span className="product-desc">{product?.user_type}</span>
          </div>
          <div className="product-item-div">
            <span className="product-title">Address : </span>
            <span className="product-desc">{product?.address}</span>
          </div>
          {product?.is_replaced ? (
            <div className="product-item-div">
              <span className="product-title">is Replaced : </span>
              <span className="product-desc">
                {product?.is_replaced ? "Yes" : "No"}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
