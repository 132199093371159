import React, { useState } from "react";
import "./Dashboard.scss";
import {
  BxBuilding,
  BxCategory,
  BxDuplicate,
  BxLogout,
  BxScan,
  BxUserPlus,
  RedCross,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import { DeletePopup } from "../../components";

function DashboardComponent() {
  const [isLogout, setIsLogout] = useState(false);
  const navigation = useNavigate();
  const cardData = [
    {
      id: 1,
      name: "User",
      icon: BxBuilding,
      path: "/users",
    },
    {
      id: 2,
      name: "Products",
      icon: BxCategory,
      path: "/products",
    },
    {
      id: 3,
      name: "Warranty",
      icon: BxScan,
      path: "/warranty",
    },
    {
      id: 4,
      name: "Replace Products",
      icon: BxDuplicate,
      path: "/replace-products",
    },
    {
      id: 5,
      name: "Retailer",
      icon: BxUserPlus,
      path: "/retailer",
    },
    {
      id: 6,
      name: "Logout",
      icon: BxLogout,
    },
  ];

  return (
    <div>
      <div className="dash-container">
        {cardData.map((item, index) => (
          <div
            className="dash-card"
            onClick={() =>
              index === 5
                ? setIsLogout(true)
                : navigation(item?.path ? item?.path : "")
            }
          >
            <img src={item.icon} />
            <span className="item-name">{item.name}</span>
          </div>
        ))}
      </div>
      {isLogout ? (
        <DeletePopup
          deleteContent={
            <div className="del-pop">
              <div className="del-pop-main">
                <img className="del-pop-icon" src={RedCross} />
              </div>
              <div className="del-pop-text-div">
                <span className="del-pop-text">
                  Are you sure want to Logout? <br />
                </span>
              </div>
              <div className="del-pop-btn">
                <button
                  className="del-pop-btn-yes"
                  onClick={() => navigation("/")}
                >
                  Yes
                </button>
                <button
                  className="del-pop-btn-no"
                  onClick={() => setIsLogout(false)}
                >
                  No
                </button>
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default DashboardComponent;
