import LoadingSpin from "react-loading-spin";
import { Password, User } from "../../assets";
import { InputText } from "../../components";
import "./Login.css";

interface LoginComponentProps {
  handleSubmit: (value: any) => void;
  data: any;
  setData: (value: any) => void;
  errors: any;
  isLoading: boolean;
}

const LoginComponent = ({
  handleSubmit,
  data,
  setData,
  errors,
  isLoading,
}: LoginComponentProps) => {
  return (
    <div>
      <section className="login-main-container">
        <div className="card">
          {/* <img className="al-logo" src={Logo} /> */}
          <h2 className="al-title">Log In</h2>
          <form onSubmit={handleSubmit}>
            <InputText
              label="Phone"
              placeholder="Phone"
              src={User}
              value={data.phone}
              maxLength={10}
              onChange={(e: any) =>
                setData((prevValue: any) => ({
                  ...prevValue,
                  phone: e.target.value,
                }))
              }
              error={errors?.phone}
            />
            <InputText
              label="Password"
              type="password"
              placeholder="Password"
              src={Password}
              value={data.password}
              onChange={(e: any) =>
                setData((prevValue: any) => ({
                  ...prevValue,
                  password: e.target.value,
                }))
              }
              error={errors?.password}
            />
            <span
              className="customer-name"
              style={{ display: "flex", justifyContent: "center" }}
              //   onClick={() => navigate("/forgot-password")}
            >
              {/* forgot password? */}
            </span>
            <div className="button-container">
              <button
                className="button"
                disabled={isLoading}
                // onClick={oldUserData}
              >
                {isLoading ? (
                  <LoadingSpin
                    primaryColor="white"
                    size="30px"
                    animationDuration="1s"
                  />
                ) : (
                  "LOG IN"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default LoginComponent;
