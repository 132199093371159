import { useEffect, useState } from "react";
import { ApiCall } from "../../config";
import QrCodeComponent from "./QrCodeComponent";
import { AlertType, alertService } from "../../utils/alert.service";
import { ValidateName } from "../../helpers";

function QrCodeController({ isClick }: any) {
  const [data, setdata] = useState<any>([]);
  const [seq, setSeq] = useState({
    prefix: "",
    suffix: "",
    seqStart: "",
    seqEnd: "",
  });
  const [selectedPage, setSelectedPage] = useState<any>(1);
  const [size, setSize] = useState(10);
  const [accordionData, setAccordionData] = useState([]);
  const [selectedAccordion, setSelectedAccordion] = useState("");
  const [isQrCodeForm, setIsQrCodeForm] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortData, setSortData] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [errors, setErrors] = useState({
    seqStart: "",
    seqEnd: "",
  });

  const AddMultiQrApi = async () => {
    try {
      const response = await ApiCall({
        endpoint: "/qr/create_multiple",
        method: "POST",
        data: {
          prefix: seq.prefix,
          suffix: seq.suffix,
          start: seq.seqStart,
          end: seq.seqEnd,
          product_id: selectedAccordion,
        },
      });
      if (response?.status === 200) {
        alertService.alert({
          type: AlertType.Success,
          message: response?.data?.message,
        });
        QrCodeListApi();
        setIsQrCodeForm(!isQrCodeForm);
        setSeq({ prefix: "", suffix: "", seqStart: "", seqEnd: "" });
        setErrors({ seqStart: "", seqEnd: "" });
      }
    } catch (error: any) {
      if (error?.data?.message) {
        alertService.alert({
          type: AlertType.Error,
          message: error?.data?.message,
        });
      }
    }
  };

  const QrCodeListApi = async () => {
    const response = await ApiCall({
      endpoint: "/qr/list/byProducts",
      method: "POST",
      data: {
        limit: size,
        page: selectedPage?.selected ?? 1,
        columnName: sortData,
        order: orderBy ?? "ASC",
        product_id: selectedAccordion,
        search: searchInput,
      },
    });
    setdata(response?.data);
  };
  const getAccordionList = async () => {
    const response = await ApiCall({
      endpoint: "/products/list",
      method: "POST",
      data: {},
    });
    setAccordionData(response?.data?.result ?? []);
  };

  useEffect(() => {
    getAccordionList();
  }, []);
  useEffect(() => {
    if (selectedAccordion && selectedAccordion.trim() !== "") {
      QrCodeListApi();
    }
  }, [selectedAccordion, selectedPage, size, orderBy]);

  const AddQrsValidator = () => {
    let newErrors: any = {};
    let isValid: boolean = true;
    const statusSeqStart = ValidateName(seq.seqStart);
    const statusSeqEnd = ValidateName(seq.seqEnd);

    if (statusSeqStart) {
      newErrors.seqStart =
        statusSeqStart === 1 ? "Sequence start is Required" : "";
      isValid = false;
    }

    if (statusSeqEnd) {
      newErrors.seqEnd = statusSeqStart === 1 ? "Sequence End is Required" : "";
      isValid = false;
    } else if (parseInt(seq.seqStart) >= parseInt(seq.seqEnd)) {
      newErrors.seqEnd = "Sequence Number is Invalid";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitHandler = () => {
    if (AddQrsValidator()) {
      AddMultiQrApi();
    }
  };

  const handleChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  if (searchInput?.length > 0) {
    data?.result?.filter((subData: any) => {
      return subData?.qr_code_data || subData?.product_name?.match(searchInput);
    });
  }

  const onShortData = (value: any) => {
    if (value !== sortData) {
      setSortData(value);
      setOrderBy("ASC");
    } else {
      setOrderBy((prev) => (prev == "ASC" ? "DESC" : "ASC"));
    }
  };
  const onAccordionPress = (value: string) => {
    setOrderBy("");
    setSelectedPage(1);
    setdata([]);
    setSelectedAccordion((prevState) => (prevState !== value ? value : ""));
  };
  const toggleAddQRs = () => {
    setIsQrCodeForm((prevState) => !prevState);
    setSeq({ prefix: "", suffix: "", seqStart: "", seqEnd: "" });
    setErrors({ seqStart: "", seqEnd: "" });
  };

  return (
    <div>
      <QrCodeComponent
        isClick={isClick}
        data={data}
        handleChangeSearch={handleChangeSearch}
        searchInput={searchInput}
        selectedPage={selectedPage}
        size={size}
        setSize={setSize}
        setSelectedPage={setSelectedPage}
        onShortData={onShortData}
        accordionData={accordionData}
        selectedAccordion={selectedAccordion}
        onAccordionPress={onAccordionPress}
        toggleAddQRs={toggleAddQRs}
        isQrCodeForm={isQrCodeForm}
        seq={seq}
        setSeq={setSeq}
        errors={errors}
        submitHandler={submitHandler}
      />
    </div>
  );
}

export default QrCodeController;
