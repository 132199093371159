import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { General } from "../components";
import {
  Retailer,
  ReplaceProduct,
  QrCodes,
  Products,
  User,
  Login,
  Dashboard,
} from "../screens";
import PrivateRoute from "./ProtectedRoute";

function RootRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <General Component={Dashboard} />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <General Component={User} />
            </PrivateRoute>
          }
        />
        <Route
          path="/products"
          element={
            <PrivateRoute>
              <General Component={Products} />
            </PrivateRoute>
          }
        />
        <Route
          path="/warranty"
          element={
            <PrivateRoute>
              <General Component={QrCodes} />
            </PrivateRoute>
          }
        />
        <Route
          path="/replace-products"
          element={
            <PrivateRoute>
              <General Component={ReplaceProduct} />
            </PrivateRoute>
          }
        />
        <Route
          path="/retailer"
          element={
            <PrivateRoute>
              <General Component={Retailer} />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default RootRouter;
