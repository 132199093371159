import { FC } from "react";
import {
  AddBrands,
  Button,
  DropDown,
  Input,
  NumberDropDown,
  Pagination,
  SearchBar,
  TableComp,
} from "../../components";
import "./User.scss";

interface UserComponentProps {
  navigation?: any;
  data?: any;
  setSize?: any;
  isClick?: any;
  openBrandForm?: boolean;
  openBrandFormClicker?: (value: any) => void;
  brandData?: any;
  setBrandData?: any;
  errors?: any;
  setErrors?: (value: any) => void;
  onDeleteHandler?: any;
  searchInput?: string;
  handleChangeSearch?: (value: any) => void;
  setActiveStep?: (value: any) => void;
  selectedPage?: any;
  setSelectedPage?: any;
  submitHandler: (value: any) => void;
  onViewHandler: (value: any) => void;
  onEditHandler: (value: any) => void;
  onShortData: any;
  size?: any;
  selectedUser: any;
  closeUserDetailsPopup: () => void;
  isLoading: boolean;
}

const DummyData = [
  { key: "No.", value: "No." },
  { key: "First Name", value: "first_name" },
  { key: "Last Name", value: "last_name" },
  { key: "Phone Number", value: "phone_number" },
  { key: "User Type", value: "user_type" },
  { key: "Business Name", value: "business_name" },
  { key: "GST", value: "gst" },
  { key: "Action", value: "Action" },
];

const UserComponent: FC<UserComponentProps> = ({
  navigation,
  isClick,
  data,
  setSize,
  openBrandForm,
  openBrandFormClicker,
  onDeleteHandler,
  searchInput,
  handleChangeSearch,
  selectedPage,
  setSelectedPage,
  brandData,
  setBrandData,
  errors,
  submitHandler,
  onViewHandler,
  onShortData,
  size,
  selectedUser,
  closeUserDetailsPopup,
  onEditHandler,
  isLoading,
}) => {
  const listData: any = [];
  {
    data?.result?.map((item: any, index: any) => {
      const list: any = [
        {
          title: "No.",
          data:
            selectedPage === 1
              ? index + 1
              : (selectedPage?.selected - 1) * size + index + 1,
        },
        {
          title: "First Nam",
          data: item,
        },
        {
          title: "Last Name",
          data: item.last_name,
        },
        {
          title: "Phone Number",
          data: item.phone_number,
        },
        {
          title: "User Type",
          data: item.user_type,
        },
        {
          title: "Business Name",
          data: item.business_name,
        },
        {
          title: "GST",
          data: item.gst,
        },
        // {
        //   title: "Address",
        //   data: item.address,
        // },
        {
          title: "Action",
          data: item,
        },
      ];
      listData.push(list);
    });
  }

  return (
    <div className={` ${isClick ? "business-class-width" : "business-class"} `}>
      <div className="business-class-card card">
        <div className="business-class-top">
          <div className="business-class-top-left">
            <span className="business-top-left-title">USERS LIST</span>
            <div className="business-class-top-left-dropdown">
              <NumberDropDown
                data={data}
                onChange={(e: any) => setSize(e.target.value)}
              />
            </div>
          </div>
          <div className="business-class-top-right">
            <Button
              className="addbrand-info-btn"
              name={"Add User"}
              onClick={openBrandFormClicker}
            />
            <SearchBar onChange={handleChangeSearch} value={searchInput} />
          </div>
        </div>
        <div className="business-class-table">
          <TableComp
            isLoading={isLoading}
            listHeaderData={DummyData}
            listData={listData}
            onDeleteHandler={(value: any) => onDeleteHandler(value)}
            onShortData={(value: any) => onShortData(value)}
            onViewHandler={(value: any) => onViewHandler(value)}
            onEditHandler={(value: any) => onEditHandler(value)}
          />
        </div>
        {listData.length > 0 ? (
          <div className="business-class-pagination">
            <Pagination
              selectedPage={selectedPage}
              totalCount={data?.count ?? 1}
              onPageChange={(page: number) => setSelectedPage(page)}
              itemsPerPage={4}
            />
          </div>
        ) : null}
      </div>
      {openBrandForm ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">USERS DETAILS</span>
              </div>
              <hr />
              <div className="addretailer-second">
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="GST"
                    type="text"
                    placeholder="GST"
                    maxLength={15}
                    minLength={15}
                    value={brandData?.gst}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        gst: e.target.value,
                      }))
                    }
                    error={errors?.gst}
                  />

                  <Input
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    value={brandData.firstname}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        firstname: e.target.value,
                      }))
                    }
                    error={errors?.firstname}
                  />

                  <Input
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    value={brandData.lastname}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        lastname: e.target.value,
                      }))
                    }
                    error={errors?.lastname}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Pan Number"
                    type="text"
                    placeholder="Pan Number"
                    value={brandData.pancard}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        pancard: e.target.value,
                      }))
                    }
                    error={errors?.pancard}
                  />
                  <div style={{ marginTop: "1.4rem" }}>
                    <DropDown
                      style={{ width: "300px" }}
                      label={"User Type"}
                      data={["ADMIN", "VENDOR", "RETAILER"]}
                      value={brandData.usertype}
                      onChange={(e: any) =>
                        setBrandData((prevValue: any) => ({
                          ...prevValue,
                          usertype: e.target.value,
                        }))
                      }
                      error={errors?.usertype}
                    />
                  </div>
                  <Input
                    label="Address"
                    type="text"
                    placeholder="Address"
                    value={brandData.address}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        address: e.target.value,
                      }))
                    }
                    error={errors?.address}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Phone Number"
                    type="text"
                    placeholder="Phone Number"
                    value={brandData.phonenumber}
                    maxLength={10}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        phonenumber: e.target.value.replace(/\D/g, ""),
                      }))
                    }
                    error={errors?.phonenumber}
                  />
                  <Input
                    label="New Password"
                    type="password"
                    placeholder="New Password"
                    value={brandData.password}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        password: e.target.value,
                      }))
                    }
                    error={errors?.password}
                  />
                  <Input
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm Password"
                    value={brandData.conpassword}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        conpassword: e.target.value,
                      }))
                    }
                    error={errors?.conpassword}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Business Name"
                    type="text"
                    placeholder="Business Name"
                    value={brandData.businessname}
                    onChange={(e: any) =>
                      setBrandData((prevValue: any) => ({
                        ...prevValue,
                        businessname: e.target.value,
                      }))
                    }
                    error={errors?.businessname}
                  />
                </div>
              </div>
              <hr />
              <div className="addCustomer-second-bottam-btn">
                <Button
                  className="addCustomer-second-bottam-btn-sub"
                  name="Submit"
                  onClick={submitHandler}
                />
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={openBrandFormClicker}
                />
              </div>
            </div>
          }
        />
      ) : null}
      {selectedUser ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">USERS DETAILS</span>
              </div>
              <hr />
              <div className="addretailer-second">
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "1" }}>
                    {Object.keys(selectedUser).map((item, index) => (
                      <div>
                        {item !== "user_id" && index % 2 === 0 && (
                          <div
                            key={item}
                            className="cl-content-details-left-side"
                            style={{ display: "flex", height: "50px" }}
                          >
                            <div className="cl-content-details-left-side-title">
                              {item.split("_").join(" ").toLocaleUpperCase()} :
                            </div>

                            <div
                              className="cl-content-details-left-side-desc"
                              style={{ fontSize: "1rem" }}
                            >
                              {selectedUser[item]}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div style={{ flex: "1" }}>
                    {Object.keys(selectedUser).map(
                      (item, index) =>
                        index % 2 !== 0 && (
                          <div
                            key={item}
                            className="cl-content-details-left-side"
                            style={{ display: "flex", height: "50px" }}
                          >
                            <div className="cl-content-details-left-side-title">
                              {item.split("_").join(" ").toLocaleUpperCase()} :
                            </div>
                            <div
                              className="cl-content-details-left-side-desc"
                              style={{ fontSize: "1rem" }}
                            >
                              {selectedUser[item]}
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={closeUserDetailsPopup}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
};

export default UserComponent;
