import { FC } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  AcceptIcon,
  DeclineIcon,
  DeleteIcon,
  EditIcon,
  PasswordEyeOpen,
} from "../../assets";
import LotiFiles from "../Loading/Lotifiles/LotiFiles";
import TableLoader from "../Loading/TableLoading";
import SortIcon from "../SortIcon/SortIcon";
import "./Table.scss";

interface TableProps {
  listData?: any;
  listHeaderData?: any;
  onDeleteHandler?: any;
  onClick?: any;
  verified?: boolean;
  declineClick?: any;
  deniedClick?: any;
  onShortData?: any;
  onViewHandler?: any;
  onEditHandler?: (value: any) => void;
  replaceNewProductDetails?: any;
  isLoading?: boolean;
}

const TableComponent: FC<TableProps> = ({
  listData,
  listHeaderData,
  onDeleteHandler,
  onClick,
  declineClick,
  deniedClick,
  onShortData,
  onViewHandler,
  onEditHandler,
  replaceNewProductDetails,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <TableLoader />
      ) : (
        <>
          {listData.length > 0 && listHeaderData ? (
            <Table>
              <Thead>
                <Tr>
                  {Object.values(listHeaderData).map((j: any, i) => (
                    <>
                      {j?.key === "No." ? (
                        <Th>{j?.key}</Th>
                      ) : j?.key === "Action" ? (
                        <Th>{j?.key}</Th>
                      ) : (
                        <Th>
                          <div className="sort-main">
                            <div>{j?.key}</div>
                            <div style={{ marginLeft: "1rem" }}>
                              <SortIcon onClick={() => onShortData(j?.value)} />
                            </div>
                          </div>
                        </Th>
                      )}
                    </>
                  ))}
                </Tr>
              </Thead>
              {Object.entries(listData).map(([key, value]: any) => (
                <>
                  <Tbody>
                    <Tr>
                      {Object.values(value).map((j: any, i) =>
                        j.title === "Action" ? (
                          <Td>
                            <div className="content-middle-action-data-div">
                              {onViewHandler ? (
                                <img
                                  style={{ paddingLeft: "10px" }}
                                  src={PasswordEyeOpen}
                                  onClick={() => onViewHandler(j.data)}
                                />
                              ) : null}
                              {onEditHandler ? (
                                <img
                                  style={{ paddingLeft: "10px" }}
                                  src={EditIcon}
                                  onClick={() => onEditHandler(j.data)}
                                />
                              ) : null}
                              {onDeleteHandler ? (
                                <img
                                  style={{ paddingLeft: "10px" }}
                                  src={DeleteIcon}
                                  onClick={() => onDeleteHandler(j.data)}
                                />
                              ) : null}
                            </div>
                          </Td>
                        ) : j.title == "Verify Status" ? (
                          <Td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {j.data.verification_status == "PENDING" ? (
                                <>
                                  <img
                                    className="cl-content-middle-action-data-div-delete"
                                    src={AcceptIcon}
                                    onClick={() => onClick(j.data)}
                                  />
                                  <img
                                    className="cl-content-middle-action-data-div-delete"
                                    src={DeclineIcon}
                                    onClick={() => declineClick(j.data)}
                                  />
                                </>
                              ) : j.data.verification_status == "DENIED" ? (
                                <div
                                  className="table-denied"
                                  onClick={() => deniedClick(j.data)}
                                >
                                  <span>Denied</span>
                                </div>
                              ) : (
                                <div className="table-verified">
                                  <div
                                    className="table-denied"
                                    onClick={() => deniedClick(j.data)}
                                  >
                                    <span>Verified</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Td>
                        ) : j.title === "New Product" ? (
                          <Td>
                            <div
                              className="content-middle-action-data-div"
                              onClick={() => replaceNewProductDetails(j?.data)}
                            >
                              {j?.data?.new_product
                                ? j?.data?.new_product
                                : "-"}
                            </div>
                          </Td>
                        ) : j.title === "First Nam" ? (
                          <Td>
                            <div
                              className="content-middle-action-data-div"
                              onClick={() => onViewHandler(j?.data)}
                            >
                              {j?.data?.first_name ? j?.data?.first_name : "-"}
                            </div>
                          </Td>
                        ) : (
                          <Td key={i}>{j.data ? j.data : "-"}</Td>
                        )
                      )}
                    </Tr>
                  </Tbody>
                </>
              ))}
            </Table>
          ) : (
            <LotiFiles message={"No Data Found!"} />
          )}
        </>
      )}
    </>
  );
};

export default TableComponent;
