import moment from "moment";
import {
  Accordion,
  AddBrands,
  Button,
  Input,
  NumberDropDown,
  Pagination,
  SearchBar,
  TableComp,
} from "../../components";

interface QrCodeProps {
  isClick: any;
  data: any;
  searchInput: any;
  isQrCodeForm: boolean;
  selectedPage: any;
  setSize: any;
  setSelectedPage: any;
  onShortData: any;
  size?: any;
  accordionData: any[];
  selectedAccordion: string;
  handleChangeSearch: (value: any) => void;
  onAccordionPress: (value: string) => void;
  toggleAddQRs: () => void;
  seq: any;
  setSeq: (value: any) => void;
  errors: any;
  submitHandler: () => void;
}

const DummyData = [
  { key: "No.", value: "No." },
  { key: "Product Name", value: "product_name" },
  { key: "Warranty (In Months)", value: "warranty_period_in_months" },
  { key: "Qr code", value: "qr_code_data" },
  { key: "Date", value: "created_at" },
];

function QrCodeComponent({
  isClick,
  data,
  searchInput,
  selectedPage,
  setSize,
  setSelectedPage,
  onShortData,
  size,
  accordionData,
  selectedAccordion,
  isQrCodeForm,
  handleChangeSearch,
  onAccordionPress,
  toggleAddQRs,
  seq,
  setSeq,
  errors,
  submitHandler,
}: QrCodeProps) {
  const listData: any = [];
  {
    data?.result?.map((item: any, index: any) => {
      const list: any = [
        {
          title: "No.",
          data:
            selectedPage === 1
              ? index + 1
              : (selectedPage?.selected - 1) * size + index + 1,
        },
        {
          title: "Product Name",
          data: item.product_name,
        },
        {
          title: "Warranty (In Months)",
          data: item.warranty_period_in_months,
        },
        {
          title: "Qr code",
          data: item.qr_code_data,
        },
        {
          title: "Date",
          data: moment(item.created_at).format("DD-MM-YYYY"),
        },
      ];
      listData.push(list);
    });
  }

  return (
    <div className={` ${isClick ? "business-class-width" : "business-class"} `}>
      <div className="business-class-card card">
        <div className="business-class-top">
          <div className="business-class-top-left">
            <span className="business-top-left-title">WARRANTY LIST</span>
            <div className="business-class-top-left-dropdown">
              <NumberDropDown
                data={data}
                onChange={(e: any) => setSize(e.target.value)}
              />
            </div>
          </div>
          <div className="business-class-top-right">
            <Button
              className="addbrand-info-btn"
              name={"Add QRs"}
              onClick={toggleAddQRs}
            />
            <SearchBar onChange={handleChangeSearch} value={searchInput} />
          </div>
        </div>

        <div>
          {accordionData.map((item) => (
            <Accordion
              name={item.product_name}
              active={selectedAccordion === item?.product_id}
              onClick={() => onAccordionPress(item.product_id)}
              content={
                <div>
                  <div className="business-class-table">
                    <TableComp
                      listHeaderData={DummyData}
                      listData={listData}
                      onShortData={(value: any) => onShortData(value)}
                    />
                  </div>
                  {listData.length > 0 ? (
                    <div className="business-class-pagination">
                      <Pagination
                        selectedPage={selectedPage}
                        totalCount={data?.count ?? 1}
                        onPageChange={(page: number) => setSelectedPage(page)}
                        itemsPerPage={4}
                      />
                    </div>
                  ) : null}
                </div>
              }
            />
          ))}
        </div>
      </div>

      {isQrCodeForm ? (
        <AddBrands
          content={
            <div>
              <div className="addCustomer-first">
                <span className="addCustomer-title">ADD QR CODES RANGE</span>
              </div>
              <hr />
              <div className="addbrand-second">
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="QR code Prefix"
                    type="text"
                    placeholder="QR code Prefix"
                    value={""}
                    onChange={(e: any) => {}}
                  />
                  <Input
                    label="QR code suffix"
                    type="text"
                    placeholder="QR code suffix"
                    value={""}
                    onChange={(e: any) => {}}
                  />
                </div>
                <div className="addCustomer-second-firstRow">
                  <Input
                    label="Sequence start"
                    type="number"
                    placeholder="Sequence start"
                    value={seq.seqStart}
                    onChange={(e: any) =>
                      setSeq((prevValue: any) => ({
                        ...prevValue,
                        seqStart: e.target.value,
                      }))
                    }
                    error={errors?.seqStart}
                  />
                  <Input
                    label="Sequence end"
                    type="number"
                    placeholder="Sequence end"
                    value={seq.seqEnd}
                    onChange={(e: any) =>
                      setSeq((prevValue: any) => ({
                        ...prevValue,
                        seqEnd: e.target.value,
                      }))
                    }
                    error={errors?.seqEnd}
                  />
                </div>
              </div>
              <hr />
              <div className="addCustomer-second-bottam-btn">
                <Button
                  className="addCustomer-second-bottam-btn-sub"
                  name="Submit"
                  onClick={submitHandler}
                />
                <Button
                  className="addCustomer-second-bottam-btn-can"
                  name="Cancel"
                  onClick={toggleAddQRs}
                />
              </div>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default QrCodeComponent;
