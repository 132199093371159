interface ValidateProps {
  id: string;
  password: string;
  name: string;
  mobile: string;
  email: string;
  gst: string;
}

export const ValidateId = (id: ValidateProps["id"]) => {
  if (id?.trim() === "") {
    return 1;
  }
  return false;
};

export const ValidatePassword = (password: ValidateProps["password"]) => {
  if (password?.trim() === "") {
    return 1;
  }
  if (
    !/^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/.test(
      password
    )
  ) {
    return 2;
  }
  return false;
};

export const ValidateLoginPassword = (password: ValidateProps["password"]) => {
  if (password?.trim() === "") {
    return 1;
  }
  if (password?.length < 6) {
    return 2;
  }
  return false;
};

export const ValidateName = (name: ValidateProps["name"]) => {
  if (name?.trim() === "") {
    return 1;
  }
  return false;
};

export const ValidateMobile = (mobile: ValidateProps["mobile"]) => {
  if (mobile?.trim() === "") {
    return 1;
  }
  if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(mobile)) {
    return 2;
  }
  return false;
};

export const ValidatePan = (pan: ValidateProps["mobile"]) => {
  if (pan?.trim() === "") {
    return 1;
  }
  if (!/[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}/.test(pan)) {
    return 2;
  }
  return false;
};

export const ValidateEmail = (email: ValidateProps["email"]) => {
  if (email?.trim() === "") {
    return 1;
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    return 2;
  }
  return false;
};

export const ValidateGst = (gst: ValidateProps["gst"]) => {
  if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst)) {
    return 1;
  }
  return false;
};
